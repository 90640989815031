<a
  class="button"
  [href]="myNotificationSettingsLink"
  target="_blank"
>
  <span
    class="spot-icon spot-icon_settings"
  ></span>
  <span>{{text.mySettings}}</span>
</a>
