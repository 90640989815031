<div *ngIf="workPackage"
     class="wp-new-top-row">
  <div class="work-packages--status-selector wp-new-top-row--element">
    <op-editable-attribute-field [resource]="workPackage"
                   [displayFieldOptions]="{ colorize: false }"
                   [wrapperClasses]="'wp-new-top-row--status -no-label'"
                   [fieldName]="'status'">
    </op-editable-attribute-field>
  </div>
  <div class="work-packages--type-selector wp-new-top-row--element">
    <op-editable-attribute-field [resource]="workPackage"
                   [wrapperClasses]="'wp-new-top-row--type -no-label'"
                   [fieldName]="'type'">
    </op-editable-attribute-field>
  </div>
</div>
