<widget-header
  [name]="widgetName"
  (onRenamed)="renameWidget($event)">

  <widget-menu
    slot="menu"
    [resource]="resource">
  </widget-menu>
</widget-header>

<op-wp-calendar [static]="true"></op-wp-calendar>
