<div>
  <op-enterprise-banner
    class="ee-attribute-highlighting-upsale"
    *ngIf="eeShowBanners"
    [linkMessage]="text.upsaleCheckOutLink"
    [textMessage]="text.upsaleAttributeHighlighting"
    [moreInfoLink]="text.more_info_link"
    opReferrer="wp-attribute-highlighting#attribute-highlighting">
  </op-enterprise-banner>

  <form *ngIf="!eeShowBanners">
    <p [textContent]="text.highlighting_mode.description"></p>
    <div class="form--field">
      <label class="form--label">
        <input type="radio"
               [attr.disabled]="disabledValue(eeShowBanners)"
               [(ngModel)]="highlightingMode"
               (change)="updateMode($event.target.value)"
               value="inline"
               name="highlighting_mode_switch">
        <span [textContent]="text.highlighting_mode.inline"></span>&nbsp;
      </label>

      <div class="form--field-container">
        <div class="form--select-container">
          <ng-select #highlightedAttributesNgSelect
                     [items]="availableInlineHighlightedAttributes"
                     [(ngModel)]="selectedAttributes"
                     [multiple]="true"
                     [disabled]="highlightingMode !== 'inline'"
                     [clearable]="false"
                     [closeOnSelect]="false"
                     (open)="onOpen(highlightedAttributesNgSelect)"
                     (change)="updateHighlightingAttributes($event)"
                     class="form--select-field -multi-select"
                     bindLabel="name"
                     name="highlighting_attributes"
                     placeholder="{{ text.highlighting_mode.inline_all_attributes }}"
                     appendTo="body">
          </ng-select>
        </div>
      </div>
    </div>

    <div class="form--field">
      <label class="form--label">
        <input type="radio"
               [attr.disabled]="disabledValue(eeShowBanners)"
               [(ngModel)]="entireRowMode"
               (change)="updateMode('entire-row')"
               [value]="true"
               name="entire_row_switch">
        <span [textContent]="text.highlighting_mode.entire_row_by"></span>
      </label>

      <div class="form--field-container">
        <div class="form--select-container">
          <ng-select #rowHighlightNgSelect
                     [items]="availableRowHighlightedAttributes"
                     [(ngModel)]="lastEntireRowAttribute"
                     [disabled]="disabledValue(eeShowBanners)"
                     [clearable]="false"
                     (open)="onOpen(rowHighlightNgSelect)"
                     (change)="updateMode($event.value)"
                     bindLabel="name"
                     bindValue="value"
                     name="selected_attribute"
                     appendTo="body"
                     id="selected_attribute"
                     class="form--select-field">
          </ng-select>
        </div>
      </div>
    </div>

    <div class="form--field">
      <label class="form--label">
        <input type="radio"
               [attr.disabled]="disabledValue(eeShowBanners)"
               [(ngModel)]="highlightingMode"
               (change)="updateMode($event.target.value)"
               value="none"
               name="highlighting_mode_switch">
        <span [textContent]="text.highlighting_mode.none"></span>
      </label>
    </div>
  </form>
</div>
