<h3 class="op-widget-box--header"
    data-test-selector="op-widget-box--header"
    [ngClass]="{ '-editable': isRenameable }">

  <ng-content select="[slot=prepend]"></ng-content>

  <editable-toolbar-title [title]="name"
                          (onSave)="renamed($event)"
                          [editable]="isRenameable"
                          class="op-widget-box--header-title">
  </editable-toolbar-title>

  <ng-content select="[slot=menu]"></ng-content>
</h3>
