<div id="div-values-{{filter.id}}" class="inline-label">
  <input [(ngModel)]="value"
         [opAutofocus]="shouldFocus"
         required
         class="advanced-filters--number-field"
         id="values-{{filter.id}}"
         name="v[{{filter.id}}]"
         type="number" />
  <label for="values-{{filter.id}}"
         [textContent]="unit"
         class="advanced-filters--affix">
  </label>
</div>
