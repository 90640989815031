<div
  class="inline-label ng-select-container"
  id="div-values-{{filter.id}}"
>
  <op-project-autocompleter
    [model]="filter.values"
    [focusDirectly]="shouldFocus"
    [multiple]="true"
    [appendTo]="'body'"
    [id]="'values-' + filter.id"
    class="advanced-filters--ng-select -multi-select"
    (change)="onChange($event)"
    [filters]="additionalProjectApiFilters"
  ></op-project-autocompleter>
</div>
