<button
  class="button toolbar-icon"
  (click)="openModal()"
>
  <svg
    share-android-icon
    class="button--icon"
    size="small"
  ></svg>
  <span class="button--text" [textContent]="text.share"></span>

  <svg
    *ngIf="showEnterpriseIcon"
    op-enterprise-addons-icon
    class="button--icon upsale-colored"
    size="small"
  ></svg>
</button>
