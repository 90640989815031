<ng-template
  #labelTemplate
  let-item
>
  <span class="ng-value-label">{{ item.name }}</span>
</ng-template>

<ng-template
  #optionTemplate
  let-item
  let-search="search"
>
  <div
    [title]="item.name"
    class="ng-option-label ellipsis"
    [ngStyle]="{ 'padding-left.px': item.numberOfAncestors * 16 }"
    [opSearchHighlight]="search"
  >{{ item.name }}</div>
  <div
    [title]="item.disabledReason"
    *ngIf="item.disabled && item.disabledReason"
    class="ng-option-label ellipsis"
    [ngStyle]="{ 'padding-left.px': item.numberOfAncestors * 16 }"
  >{{ item.disabledReason }}</div>
</ng-template>
