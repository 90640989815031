<op-content-loader viewBox="0 0 928 186">
  <svg:rect x="0" y="4" width="100%" height="32" rx="1" />

  <svg:rect x="0" y="40" width="100%" height="32" rx="1" />

  <svg:rect x="0" y="76" width="100%" height="32" rx="1" />

  <svg:rect x="0" y="112" width="100%" height="32" rx="1" />

  <svg:rect x="0" y="148" width="100%" height="32" rx="1" />
</op-content-loader>
