<work-package-comment [workPackage]="workPackage">
  <ng-template>
    <div class='work-package-details-activities-list'>
      <div *ngFor="let inf of latestActivityInfo; trackBy:trackByIdentifier"
          class="work-package-details-activities-activity">
        <activity-entry [workPackage]="workPackage"
                        [activity]="inf.activity"
                        [activityNo]="inf.number(inf.isReversed)"
                        [hasUnreadNotification]="hasUnreadNotification(inf.href) | async"
                        [isInitial]="inf.isInitial()">
        </activity-entry>
      </div>
    </div>
  </ng-template>
</work-package-comment>
