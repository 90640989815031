<op-content-loader viewBox="0 0 200 140">
  <svg:rect x="0" width="100%" height="12" rx="1" />

  <svg:rect x="10" y="16" width="100%" height="12" rx="1" />

  <svg:rect x="10" y="32" width="100%" height="12" rx="1" />

  <svg:rect x="20" y="48" width="100%" height="12" rx="1" />

  <svg:rect x="20" y="64" width="100%" height="12" rx="1" />

  <svg:rect x="0" y="80" width="100%" height="12" rx="1" />

  <svg:rect x="10" y="96" width="100%" height="12" rx="1" />

  <svg:rect x="10" y="112" width="100%" height="12" rx="1" />

  <svg:rect x="0" y="128" width="100%" height="12" rx="1" />
</op-content-loader>
