<div class="op-ckeditor--wrapper">
  <op-ckeditor
    [context]="context"
    [content]="initialContent"
    (initializeDone)="setup($event)"
    (contentChanged)="markEdited()"
    (saveRequested)="saveForm()"
  ></op-ckeditor>
</div>

<fieldset
  class="form--fieldset"
  *ngIf="halResource && halResource.attachments"
>
  <legend class="form--fieldset-legend">
    {{ text.attachments }}
  </legend>
  <op-attachments
    [resource]="halResource"
    [destroyImmediately]="false"
  ></op-attachments>
</fieldset>
