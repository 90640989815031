<div
  class="spot-link"
  [title]="text.open_dialog"
  (click)="handleClick($event)"
  (keydown.enter)="handleClick($event)"
  (keydown.space)="handleClick($event)"
  role="button"
  tabindex="0"
  data-test-selector="static-attribute-help-text--icon"
>
  <span class="spot-icon spot-icon_help1"></span>
</div>
