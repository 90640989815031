<div
  class="op-ian-actors--date"
  [title]="fixedTime"
  [textContent]="relativeTime$ | async"
></div>
<div class="op-ian-actors--container">
  <ng-container *ngFor="let actor of actors | slice:0:3; let idx = index; let last = last">
    <span *ngIf="last && actors.length > 1 && actors.length < 4" textContent=" {{ text.and }} "></span>
    <op-principal
      class="op-ian-item--actors"
      [principal]="actor"
      [hideName]="false"
      [hideAvatar]="true"
      [link]="!deviceService.isMobile"
    ></op-principal>
    <span *ngIf="(idx === 0 && actors.length > 2) || (idx === 1 && actors.length > 3)" [textContent]="', '"></span>
  </ng-container>
  <span *ngIf="actors.length > 3"> {{ text_for_additional_authors(actors.length - 3) }}</span>
</div>
