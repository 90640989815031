<div
  class="spot-modal spot-modal_wide op-share-wp-modal"
>
  <div
    class="spot-modal--header"
  >
    <div
      id="spotModalTitle"
      class="spot-modal--header-title"
      [textContent]="this.text.title">
    </div>
    <button
      class="button button_no-margin -transparent spot-modal--header-close-button"
      [attr.aria-label]="text.button_close"
      (click)="closeMe($event)"
      data-test-selector="op-share-wp-modal--close-icon"
    >
      <svg
        x-icon
        size="small"
        class="spot-modal--header-close-button-icon"
      ></svg>
    </button>
  </div>

  <div class="spot-modal--body spot-container">
    <turbo-frame id="work_package_share_list" src="{{this.frameSrc}}">
      <op-content-loader
        viewBox="0 0 180 80"
      >
        <svg:rect height="10" rx="2" ry="2" width="110" x="0" y="0"/>
        <svg:rect height="10" rx="2" ry="2" width="25" x="120" y="0"/>
        <svg:rect height="10" rx="2" ry="2" width="25" x="155" y="0"/>
        <svg:rect height="25%" rx="2" ry="2" width="180" x="0" y="16"/>
      </op-content-loader>
    </turbo-frame>
  </div>
</div>
