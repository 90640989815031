<button
  class="op-app-menu--item-action"
  id="main-menu-toggle"
  aria-haspopup="true"
  type="button"
  [attr.title]="toggleTitle"
  (click)="toggle($event)"
>
  <op-icon class="icon-hamburger" aria-hidden="true"></op-icon>
  <op-icon class="icon-close" aria-hidden="true"></op-icon>
</button>
