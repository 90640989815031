<button
  type="button"
  class="button"
  (click)="markAllRead()"
>
  <span
    class="spot-icon spot-icon_mark-all-read"
  ></span>
  <span>{{text.mark_all_read}}</span>
</button>
